import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/SeamlessEmbed"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import { graphql } from "gatsby"

const GetStartedPage = props => {
  const post = props.data.allUniquePagesJson.nodes[0]
  return (
    <SharedStateProvider>
      <Layout layoutClass={"contact-fab"}>
        <SEO title={post.metaTitle} description={post.metaDescription} />
        <div className="joshua-tree-content">
          <div className="columns">
            <div className="column">
              <div
                className="columns top-section text-section is-mobile white-back"
                style={{ paddingBottom: "48px" }}
              >
                <div className="column is-4 mobile-hide"></div>
                <div className="column is-16 mobile-full">
                  <div>
                    <h1>{post.heading}</h1>
                    <p className="large">{post.blurb}</p>
                  </div>
                </div>
                <div className="column is-4 mobile-hide"></div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div
                className="columns body-section text-section is-mobile form-area"
                style={{ paddingTop: "0" }}
              >
                <div className="column is-5 mobile-hide"></div>
                <div className="column is-14 mobile-full">
                  <div>
                    <a
                      className="standard-button contained"
                      href="/contact-us-in-mount-pleasant-sc/"
                    >
                      Contact Us
                    </a>
                  </div>
                  <div className="column is-5 mobile-hide"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query getStartedPage($loneUrl: String!) {
    allUniquePagesJson(filter: { title: { eq: $loneUrl } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        blurb
      }
    }
  }
`

export default GetStartedPage
